'use strict';

// Uses: https://hyperapp.js.org/
import Drawer from './drawer';

/* 
    Convert each bio to a drawer app.
    The default, detailed bio info is moved into a drawer element and replaced by a summary element.
    The summary element includes a "View Bio" button which opens the drawer.
 */
const bios = document.getElementsByClassName('Bio');
Array.prototype.filter.call(bios, (el) => { 
    new Drawer(el); 
});


// Uses: https://github.com/cferdinandi/smooth-scroll
import SmoothScroll from 'smooth-scroll';
new SmoothScroll('a[href*="#"]', {
    offset: 30
});

