'use strict';
import { h, app } from "hyperapp";

export default function( container ) {

    // console.log( (container.querySelector('.bio').innerHTML) );
    // console.log(container.querySelector('.bio').nodeValue);

    let scroll_pos = 0;

    const state = {
        open: false,
        details: {
            name: container.querySelector('h3').textContent,
            photo: (container.querySelector('img') !== null) ? container.querySelector('img').getAttribute('src') : '',
            title: container.querySelector('.title').textContent,
            bio: container.querySelector('.bio').innerHTML
        }
    }

    const actions = {
        open: () => state => ({ open: true }),
        close: () => state => ({ open: false })
    }

    const toggleScrim = (element, oldAttributes) => {
        if( element.hasAttribute('hidden') ) {
            document.documentElement.classList.remove('scrim_is_visible');

            // Reset body scroll position
            window.scroll(0, scroll_pos);
            document.body.style.top = '';

            // Reset body margin
            document.body.style.marginRight = "";
        } else {
            // Force current scroll position onto body, changing to "fixed" will jump page to top
            scroll_pos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            document.body.style.top = -1 * scroll_pos + 'px';

            // Get current body width
            const body_width = document.body.offsetWidth;

            document.documentElement.classList.add('scrim_is_visible');

            // Pad body if new width is different from original
            document.body.style.marginRight = document.body.offsetWidth - body_width + "px";
        }
    }

    const view = (state, actions) => (
        <div class=''>
            <div class='Bio_summary'>
                { state.details.photo ? (<img src={state.details.photo} alt={"Photo: " + state.details.name} width="200" height="200" />) : '' }
                <h3>{state.details.name}</h3>
                { h( "p", { class: 'title', innerHTML: state.details.title } ) }
                <a onclick={() => actions.open()} class='Bio_open'>View Bio</a>
            </div>
            <div class='Scrim' hidden={!state.open} onupdate={toggleScrim} onclick={() => actions.close()}></div>
            <div class='Drawer' hidden={!state.open}>
                <div class='Drawer_scrollable'>
                    <div class='Bio_detail Drawer_content'>
                        { state.details.photo ? (<img src={state.details.photo} alt={"Photo: " + state.details.name} width="400" height="400" />) : '' }
                        <h3>{state.details.name}</h3>
                        { h( "p", { class: 'title', innerHTML: state.details.title } ) }
                        { h( "p", { class: 'bio', innerHTML: state.details.bio } ) }
                    </div>
                </div>
                <a class='Drawer_close' onclick={() => actions.close()}></a>
            </div>
        </div>
    )

    return app(state, actions, view, container);
};
